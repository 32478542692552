import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiService } from '../service/api/api.service';
import { CompanyService } from '../service/api/company/company.service';
import { concatMap, EMPTY } from 'rxjs';
export interface company_type {
  type: any[];
}

export class FetchCompanyType {
  static readonly type = '[CompanyState] FetchCompanyType';
}

@State<company_type>({
  name: 'company_type',
  defaults: {
    type: [],
  },
  children: [],
})
@Injectable()
export class CompanyTypeState {
  constructor(private companyService: CompanyService) {}

  @Selector()
  static getInstance(state: company_type): company_type {
    return state;
  }

  @Selector()
  static getList(state: company_type): { label: string; value: string }[] {
    return state.type;
  }

  @Action(FetchCompanyType)
  fetchType(states: StateContext<company_type>, action: FetchCompanyType) {
    return this.companyService.getType().pipe(
      concatMap((res: any) => {
        console.log(res);
        states.patchState({
          type: res.message.companyTypeList,
        });
        return EMPTY;
      })
    );
  }
}
