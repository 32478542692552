import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getType(): Observable<any> {
    return this.http.post<any>(environment.host + '/company_type.php', {
      gofor: 'getAllCompanyType',
    });
  }
}
